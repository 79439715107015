import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

//SUBCOMPONENTS
import {
    IdeaBuilderLogin,
    IdeaBuilderSolutions,
    IdeaBuilder,
    BuilderContactModal,
} from '../components/ideabuilder';
import AppLayout from '../layout/AppLayout';

const BuilderMain = () => {
    //redux non functions
    const { activePage } = useSelector((state) => state.builderContents);

    return (
        <>
            <Helmet>
                <title>Zipher Solutions</title>
                <meta name="robots" content="index, follow" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="English" />

                <meta name="title" content="Zipher Solutions" />
                <meta
                    name="description"
                    content="Zipher Solutions provides intelligent, modern, and effective solutions in the field of software, hardware, networking, cloud infrastructure, and IoT."
                />
                <meta
                    name="keywords"
                    content="information technology, it, services, solutions, iot, software, hardware, development, networking, cloud infrastructure, services, web3, technology"
                />
                <meta
                    itemprop="image"
                    content="https://ziphersolutions.com/images/logo_zipher.png"
                />

                {/* <!-- Open Graph / Facebook --> */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://ziphersolutions.com/" />
                <meta property="og:title" content="Zipher Solutions" />
                <meta
                    property="og:description"
                    content="Zipher Solutions provides intelligent, modern, and effective solutions in the field of software, hardware, networking, cloud infrastructure, and IoT."
                />
                <meta
                    property="og:image"
                    content="https://ziphersolutions.com/images/logo_zipher.png"
                />

                {/* <!-- Twitter --> */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://ziphersolutions.com/" />
                <meta property="twitter:title" content="Zipher Solutions" />
                <meta
                    property="twitter:description"
                    content="Zipher Solutions provides intelligent, modern, and effective solutions in the field of software, hardware, networking, cloud infrastructure, and IoT."
                />
                <meta
                    property="twitter:image"
                    content="https://ziphersolutions.com/images/logo_zipher.png"
                />
            </Helmet>
            <AppLayout>
                <section className="builder_main d-flex-col align-items-center">
                    <BuilderContactModal />
                    {activePage === 'login' ? <IdeaBuilderLogin /> : null}
                    {activePage === 'solutions' ? <IdeaBuilderSolutions /> : null}
                    {activePage === 'builder' ? <IdeaBuilder /> : null}
                    {/* <IdeaBuilder /> */}
                </section>
            </AppLayout>
        </>
    );
};

export default BuilderMain;
