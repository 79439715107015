import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Button, Alert } from 'antd';

//ICON
import icon_otp from '../../../../../static/svg/build-icon-phone.svg';

//REDUX
import { builderContentActions } from '../../../../redux/reducers/builder.reducers';

const FormOTP = () => {
    //redux
    const dispatch = useDispatch();

    //redux functions
    const { resetStateBuilder, updateListBuilder } = builderContentActions;

    //redux non functions
    const { pageLogin } = useSelector((state) => state.builderContents);
    //::
    const { form } = pageLogin;

    //state
    const [showAlert, setShowAlert] = useState(false);

    //functions
    const onFinish = (values) => {
        const { otp } = values;
        dispatch(
            updateListBuilder({
                activePage: 'solutions',
                pageLogin: {
                    activeComponent: 'form',
                },
            }),
        );
    };
    const handleChangeNumber = () => {
        dispatch(
            updateListBuilder({
                pageLogin: {
                    activeComponent: 'form',
                },
            }),
        );
    };

    const inputOnChange = () => {
        setShowAlert(false);
    };

    //
    return (
        <section className="builder_login_otp d-flex-col align-items-center justify-content-center">
            <div className="builder_login_otp_title d-flex-col align-items-center justify-content-center text-center">
                <img src={icon_otp} alt="OTP Icon by Zipher" />
                <h2>Just one last step!</h2>
                <p>
                    To verify it’s really you, please enter the verification code that was sent to:{' '}
                    <span>{form.mobile}</span>
                </p>
            </div>
            <Form name="basic" onFinish={onFinish} layout="vertical">
                {showAlert ? (
                    <Alert
                        message="Invalid Verification OTP!"
                        description="Double check OTP number then try again."
                        type="error"
                        closable
                    />
                ) : null}

                <Form.Item
                    name="otp"
                    rules={[
                        {
                            required: true,
                            message: 'Valid OTP number is required!',
                        },
                    ]}
                >
                    <Input
                        autoComplete="none"
                        placeholder="Enter OTP Number"
                        type="number"
                        onChange={inputOnChange}
                    />
                </Form.Item>
                <Form.Item className="text-center">
                    <Button className="login-button" type="primary" htmlType="submit">
                        VERIFY
                    </Button>
                    <Button onClick={handleChangeNumber} type="link" htmlType="reset">
                        Change Number
                    </Button>
                </Form.Item>
            </Form>
            <p className="modal_verify_resend text-center">
                Resend code (60s) <span> 3 retries left </span>
            </p>
        </section>
    );
};

export default FormOTP;
