import React, { useState } from 'react';
import { Checkbox, Button, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';

//REDUX
import { builderContentActions } from '../../../redux/reducers/builder.reducers';

//SVGs
import iot_bullet from '../../../../static/svg/builder-iot-solutions.svg'; //builder-iot-solutions.svg
import soft_bullet from '../../../../static/svg/builder-software-solutions.svg';

export const IdeaBuilderSolutions = () => {
    //redux
    const dispatch = useDispatch();

    //redux functions
    const { updateListBuilder } = builderContentActions;
    //states
    const text = <span>Please select atleast one option to continue.</span>;
    const [selectedValue, setSelectedValue] = useState([]);

    const options = [
        {
            label: (
                <>
                    <img src={iot_bullet} alt="" />
                    <div className="desc d-flex-col">
                        <p>IoT Solutions</p>
                        <p>
                            Supporting text about this field. Let’s add some component samples to as
                            guide for visitors.
                        </p>
                    </div>
                </>
            ),
            value: 'iot',
        },
        {
            label: (
                <>
                    <img src={soft_bullet} alt="" />
                    <div className="desc d-flex-col">
                        <p>Software Solutions</p>
                        <p>
                            Supporting text about this field. Let’s add some component samples to as
                            guide for visitors.
                        </p>
                    </div>
                </>
            ),
            value: 'software',
        },
    ];

    const selectOnChange = (checkedValues) => {
        setSelectedValue(checkedValues);
    };
    const toBuilder = () => {
        dispatch(
            updateListBuilder({
                activePage: 'builder',
                pageLogin: {
                    solutions: selectedValue,
                },
            }),
        );
    };
    return (
        <section className="builder_options d-flex-col align-items-center justify-content-center gap-3">
            <div className="builder_options_title text-center">
                <h3>What solutions will be applied to your business idea or project?</h3>
                <p>You may select both of the options.</p>
            </div>
            <Checkbox.Group options={options} defaultValue={[]} onChange={selectOnChange} />
            {selectedValue.length ? (
                <Button onClick={toBuilder} type="primary">
                    Continue
                </Button>
            ) : (
                <Tooltip color="#3cc2dd" placement="top" title={text}>
                    <Button disabled type="primary">
                        Continue
                    </Button>
                </Tooltip>
            )}
        </section>
    );
};
