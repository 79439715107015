import React from 'react';

//SVGs
import placeholder from '../../../../../../static/svg/quiz-svg-footer.svg';

export const IBCEmpty = () => {
    return (
        <section className="idea_builder_content_empty d-flex-col align-items-center justify-content-center text-center">
            <img src={placeholder} alt="Zipher Tech Placeholder Img" />
            <p>
                Add your IoT components <br /> from the left panel
            </p>
        </section>
    );
};

export default IBCEmpty;
