import React from 'react';
import { Popover, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

//REDUX
import { builderContentActions } from '../../../../redux/reducers/builder.reducers';

//
import select from '../../../../../static/svg/select.svg';

//SUBCOMPONENTS
import IBSMenu from './subcomponents/IBSMenu';
import IBSTitle from './subcomponents/IBSTitle';
import IBSModal from './subcomponents/IBSModal';

const IdeaBuilderSider = () => {
    //redux
    const dispatch = useDispatch();

    //redux functions
    const { updateListBuilder } = builderContentActions;

    //redux non functions
    const { tutsMode } = useSelector((state) => state.builderContents);

    //::
    const { isActivated, activeStep } = tutsMode;

    //
    const skipTuts = () => {
        dispatch(
            updateListBuilder({
                tutsMode: {
                    isActivated: false,
                },
            }),
        );
    };
    const nextStepTuts = () => {
        dispatch(
            updateListBuilder({
                tutsMode: {
                    activeStep: 'sidermenu',
                },
            }),
        );
    };

    //
    const popContent = (
        <div className="popover_custom_content">
            <p>You may browse the components for the solutions you need here.</p>
            <div className="popover_custom_content_actions d-flex-row align-items-center justify-content-between">
                <Button className="skip" type="link" onClick={skipTuts}>
                    Skip
                </Button>
                <p>1/4</p>
                <Button className="next" type="primary" onClick={nextStepTuts}>
                    Next
                </Button>
            </div>
        </div>
    );
    return (
        <section className="idea_builder_sider">
            <IBSTitle />
            <IBSMenu />
            <IBSModal />
            {activeStep === 'sider' && isActivated ? (
                <div className="idea_builder_sider_tuts ">
                    <Popover placement="right" content={popContent} title="Title">
                        <div className="hoverme d-flex-col align-items-center justify-content-center">
                            <img src={select} alt="" />
                        </div>
                    </Popover>
                </div>
            ) : null}
        </section>
    );
};

export default IdeaBuilderSider;
