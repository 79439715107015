import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//PLUGGIN
import { InfoCircleOutlined } from '@ant-design/icons';
import { AiFillPlusCircle } from 'react-icons/ai';
import { Modal, Form, Button, Input, Select, Tag, message } from 'antd';

//REDUX
import { builderContentActions } from '../../../../../redux/reducers/builder.reducers';

const IBSModal = () => {
    //redux
    const dispatch = useDispatch();

    //redux functions
    const { updateListBuilder, updateIOTComponent, updateIOTMenu } = builderContentActions;

    //redux non functions
    const { pageBuilder } = useSelector((state) => state.builderContents);

    //::
    const { modal, menuIOT } = pageBuilder;

    //states
    const [customName, setCustomName] = useState('');
    const [menuArr, setMenuArr] = useState(
        menuIOT.map((data) => {
            return data.header;
        }),
    );
    const { Option } = Select;

    const onCustomNameChange = (e) => {
        setCustomName(e.target.value);
    };

    const addItem = (e) => {
        e.preventDefault();
        if (customName === '') {
            message.warning('Custom category input name must not be empty!');
            return;
        }
        setMenuArr([...menuArr, customName]);
        setCustomName('');
    };

    /////
    function tagRender(props) {
        const { label, closable, onClose } = props;
        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color="#D5EEF6"
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 4 }}
            >
                {label}
            </Tag>
        );
    }

    //functions
    const handleOk = () => {
        dispatch(
            updateListBuilder({
                pageBuilder: {
                    modal: {
                        isShow: false,
                    },
                },
            }),
        );
    };

    const onFinish = (values) => {
        const { category, name } = values;
        if (category && name) {
            category.map((categ) => {
                if (categ === 'Sensing Layer') {
                    dispatch(updateIOTComponent({ name, categ }));
                } else if (categ === 'Network Layer') {
                    dispatch(updateIOTComponent({ name, categ }));
                } else if (categ === 'Application Layer') {
                    dispatch(updateIOTComponent({ name, categ }));
                } else {
                    const ifExist = menuIOT.filter((menu) => {
                        return menu.header === categ;
                    });
                    if (ifExist.length) {
                        dispatch(updateIOTComponent({ name, categ }));
                    } else {
                        dispatch(
                            updateIOTMenu({
                                category: categ,
                                header: categ,
                                components: [{ name, categ }],
                            }),
                        );
                    }
                }
            });
            document.getElementById('custom_component').reset();
            handleOk();
        }
        // saveAndAddWorkspace(values);
        // saveComponents(values);
    };

    // const saveAndAddWorkspace = (values) => {
    //   console.log('save and add', values);
    // };
    // const saveComponents = (values) => {
    //   console.log('save components', values);
    // };

    return (
        <>
            <Modal
                title="Custom Component"
                className="modal_custom_component"
                visible={modal.isShow}
                onOk={handleOk}
            >
                <Form id="custom_component" name="basic" onFinish={onFinish} layout="vertical">
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                            {
                                required: true,
                                message: 'Component name number is required!',
                            },
                        ]}
                    >
                        <Input autoComplete="none" placeholder="Enter component name" />
                    </Form.Item>
                    <Form.Item
                        name="category"
                        label="Category"
                        tooltip={{
                            title: 'You can tag  multiple categories to this component. You may also add a new category.',
                            icon: <InfoCircleOutlined />,
                        }}
                        rules={[
                            {
                                required: true,
                                message: 'Component name number is required!',
                            },
                        ]}
                    >
                        <Select
                            mode="multiple"
                            showArrow
                            tagRender={tagRender}
                            defaultValue={[]}
                            placeholder="Select component category"
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <div className="custom_select_item d-flex-row align-items-center justify-content-between gap-1">
                                        <Input
                                            placeholder="New category"
                                            value={customName}
                                            onChange={onCustomNameChange}
                                        />
                                        <AiFillPlusCircle onClick={addItem} />
                                    </div>
                                </>
                            )}
                        >
                            {menuArr.map((item) => (
                                <Option key={item}>{item}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <div className="action_btns d-flex-row align-items-center justify-content-between">
                        <Button onClick={handleOk} htmlType="reset" className="close" type="link">
                            Close
                        </Button>
                        <div className="action_btns_save d-flex-row align-items-center gap-1">
                            <Button
                                htmlType="submit"
                                // onClick={saveAndAddWorkspace}
                            >
                                Save and add to workspace
                            </Button>
                            <Button
                                htmlType="submit"
                                // onClick={saveComponents}
                            >
                                Save component
                            </Button>
                        </div>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default IBSModal;
