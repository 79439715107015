import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

//PLUGINS
import { Popover, Button } from 'antd';

//REDUX
import { builderContentActions } from '../../../../../redux/reducers/builder.reducers';

//SVGs
import select from '../../../../../../static/svg/select.svg';
import { BsFillPlusSquareFill } from 'react-icons/bs';

const IBSTitle = () => {
    //redux
    const dispatch = useDispatch();

    //redux functions
    const { updateListBuilder } = builderContentActions;

    //redux non functions
    const { tutsMode } = useSelector((state) => state.builderContents);

    //::
    const { isActivated, activeStep } = tutsMode;

    //functions
    const addCustom = () => {
        dispatch(
            updateListBuilder({
                pageBuilder: {
                    modal: {
                        isShow: true,
                    },
                },
            }),
        );
    };

    //
    const skipTuts = () => {
        dispatch(
            updateListBuilder({
                tutsMode: {
                    isActivated: false,
                },
            }),
        );
    };
    const nextStepTuts = () => {
        dispatch(
            updateListBuilder({
                tutsMode: {
                    activeStep: 'content',
                },
            }),
        );
    };

    //
    const popContent = (
        <div className="popover_custom_content">
            <p>
                Can’t find the component you need? You may use create a custom one to fit your
                needs.
            </p>
            <div className="popover_custom_content_actions d-flex-row align-items-center justify-content-between">
                <Button className="skip" type="link" onClick={skipTuts}>
                    Skip
                </Button>
                <p>3/4</p>
                <Button className="next" type="primary" onClick={nextStepTuts}>
                    Next
                </Button>
            </div>
        </div>
    );
    //
    return (
        <div className="idea_builder_sider_title d-flex-col gap-3">
            <div className="idea_builder_sider_title_text">
                <p>Components</p>
                <p>Browse through the components below and click the add button to include them.</p>
            </div>
            <div className="idea_builder_sider_title_custom d-flex-row align-items-center justify-content-between">
                <p>Custom:</p>
                <div
                    onClick={addCustom}
                    className="add_component d-flex-row align-items-center gap-1"
                >
                    <BsFillPlusSquareFill />
                    Custom Component
                </div>
            </div>
            {activeStep === 'sidertitle' && isActivated ? (
                <div className="idea_builder_sider_title_tuts">
                    <Popover placement="right" content={popContent} title="Title">
                        <div className="hoverme d-flex-col align-items-center justify-content-center">
                            <img src={select} alt="" />
                        </div>
                    </Popover>
                </div>
            ) : null}
            {(activeStep === 'sidermenu' || activeStep === 'content') && isActivated ? (
                <div className="idea_builder_sider_title_shadow"></div>
            ) : null}
        </div>
    );
};

export default IBSTitle;
