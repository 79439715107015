import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

//PLUGINS
import { Collapse, message, Popover, Button } from 'antd';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import { BiCustomize } from 'react-icons/bi';
import select from '../../../../../../static/svg/select.svg';

//REDUX
import { builderContentActions } from '../../../../../redux/reducers/builder.reducers';

const IBSMenu = () => {
    //redux
    const dispatch = useDispatch();

    //redux functions
    const { updateWSMenu, updateWSComponents, updateListBuilder } = builderContentActions;

    //redux non functions
    const { pageBuilder, pageWorkspace, tutsMode } = useSelector((state) => state.builderContents);
    //::
    const { menuIOT } = pageBuilder;
    const { mainMenu } = pageWorkspace;
    const { isActivated, activeStep } = tutsMode;

    //states
    const { Panel } = Collapse;

    //functions
    const activeKeys = menuIOT.map((data) => {
        return data.category;
    });

    const addCustom = (value) => {
        //check if object existed
        const myComponents = mainMenu.filter((data) => {
            return data.header === value.categ;
        });

        if (myComponents.length) {
            const existedObj = myComponents[0].components.filter((obj) => {
                return obj.name === value.name;
            });
            //exciting part
            if (existedObj.length) {
                message.info(`${value.categ} already has ${value.name}.`);
            } else {
                ///
                const ifExist = mainMenu.filter((menu) => {
                    return menu.header === value.categ;
                });
                if (ifExist.length) {
                    dispatch(updateWSComponents(value));
                } else {
                    dispatch(
                        updateWSMenu({
                            category: value.categ,
                            header: value.categ,
                            components: [value],
                        }),
                    );
                }
            }
        } else {
            dispatch(
                updateWSMenu({
                    category: value.categ,
                    header: value.categ,
                    components: [value],
                }),
            );
        }
    };

    //
    const skipTuts = () => {
        dispatch(
            updateListBuilder({
                tutsMode: {
                    isActivated: false,
                },
            }),
        );
    };
    const nextStepTuts = () => {
        dispatch(
            updateListBuilder({
                tutsMode: {
                    activeStep: 'sidertitle',
                },
            }),
        );
    };

    //
    const popContent = (
        <div className="popover_custom_content">
            <p>
                These are the existing pre-set components that can be useful for your idea. Simply
                click the “+” button to add them.
            </p>
            <div className="popover_custom_content_actions d-flex-row align-items-center justify-content-between">
                <Button className="skip" type="link" onClick={skipTuts}>
                    Skip
                </Button>
                <p>2/4</p>
                <Button className="next" type="primary" onClick={nextStepTuts}>
                    Next
                </Button>
            </div>
        </div>
    );

    return (
        <div className="idea_builder_sider_options d-flex-col">
            <p className="idea_builder_sider_options_title">IOT SOLUTIONS</p>
            <Collapse defaultActiveKey={activeKeys}>
                {menuIOT.map((layer) => {
                    const { category, header, components } = layer;
                    return (
                        <>
                            <Panel header={header} key={category}>
                                <div className="menu">
                                    {components.map((data, i) => (
                                        <div
                                            key={i}
                                            onClick={() => addCustom(data)}
                                            className="menu_item d-flex-row align-items-center justify-content-between"
                                        >
                                            <div className="menu_item_text d-flex-row align-items-center gap-1">
                                                {data.icon ? (
                                                    <img src={data.icon} alt="Zipher Icon" />
                                                ) : (
                                                    <BiCustomize />
                                                )}
                                                <p>{data.name}</p>
                                            </div>
                                            <BsFillPlusSquareFill />
                                        </div>
                                    ))}
                                </div>
                            </Panel>
                        </>
                    );
                })}
            </Collapse>
            {activeStep === 'sidermenu' && isActivated ? (
                <div className="idea_builder_sider_options_tuts">
                    <Popover placement="right" content={popContent} title="Title">
                        <div className="hoverme d-flex-col align-items-center justify-content-center">
                            <img src={select} alt="" />
                        </div>
                    </Popover>
                </div>
            ) : null}
            {(activeStep === 'sidertitle' || activeStep === 'content') && isActivated ? (
                <div className="idea_builder_sider_options_shadow"></div>
            ) : null}
        </div>
    );
};

export default IBSMenu;
