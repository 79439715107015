import React, { useState } from 'react';

//
import { BsFillPlusSquareFill, BsArrowLeftRight, BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { CgArrowsMergeAltH } from 'react-icons/cg';

const IBCSymbol = () => {
    //states
    const [activeSymbol, setActiveSymbol] = useState({
        key: 'main',
        header: 'Symbols',
        icon: <BsFillPlusSquareFill />,
    });

    //
    const symbolOptions = [
        {
            key: 'arrowleft',
            icon: <BsArrowLeft />,
        },
        {
            key: 'arrowright',
            icon: <BsArrowRight />,
        },
        {
            key: 'arrowleftright',
            icon: <BsArrowLeftRight />,
        },
        {
            key: 'merge',
            icon: <CgArrowsMergeAltH />,
        },
    ];
    const triggerSymbol = (data) => {
        setActiveSymbol(data);
    };

    //
    return (
        <div className="frame_container_symbol ">
            <div
                className={`${
                    activeSymbol.key === 'main' ? 'symbol_selected_initial' : 'symbol_selected'
                } d-flex-row align-items-center gap-1`}
            >
                {activeSymbol.icon}
                <p>{activeSymbol.header}</p>
            </div>
            <div className="symbol_selected_options">
                {symbolOptions.map((symbol, i) => (
                    <div
                        onClick={() => triggerSymbol(symbol)}
                        key={i}
                        className="symbol_selected_options_item d-flex-row align-items-center justify-content-center"
                    >
                        {symbol.icon}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default IBCSymbol;
