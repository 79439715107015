import React from 'react';

//SUBCOMPONENTS
import IdeaBuilderContent from './IdeaBuilderContent/IdeaBuilderContent';
import IdeaBuilderSider from './IdeaBuilderSider/IdeaBuilderSider';

export const IdeaBuilder = () => {
    return (
        <div className="idea_builder d-flex-row align-items-center justify-content-center">
            <IdeaBuilderSider />
            <IdeaBuilderContent />
        </div>
    );
};
