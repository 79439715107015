import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Alert, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import ReactPhoneInput from 'react-phone-number-input';

//REDUX
import { builderContentActions } from '../../../../redux/reducers/builder.reducers';

const FormCreds = () => {
    //redux
    const dispatch = useDispatch();

    //redux functions
    const { resetStateBuilder, updateListBuilder } = builderContentActions;

    //redux non functions
    const { pageLogin } = useSelector((state) => state.builderContents);
    //::
    const { industries, form } = pageLogin;

    //states
    const { Option } = Select;
    const [disabledForm, setDisabledForm] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [isEmail, setIsEmail] = useState(true);
    const [isMobile, setIsMobile] = useState(true);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertDesc, setAlertDesc] = useState('');
    const [phoneValue, setPhoneValue] = useState('');

    //functions
    const inputOnChange = () => {
        setIsEmail(true);
        setIsMobile(true);
    };

    useEffect(() => {
        inputOnChange();
    }, [phoneValue]);

    const onFinish = (values) => {
        // setLoadingForm(true);
        //states
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const { business, email, mobile, industry } = values;

        //email validation
        if (email.match(mailformat)) {
            setIsEmail(true);
        } else {
            setIsEmail(false);
        }
        //mobile validation
        if (mobile.length >= 14) {
            setIsMobile(false);
            setAlertDesc('Mobile number is too long. Check input to continue.');
        } else if (mobile.length <= 12) {
            setIsMobile(false);
            setAlertDesc('Mobile number is too Short. Check input to continue.');
        }
        //API call Start HERE
        if (email.match(mailformat) && mobile.length == 13) {
            dispatch(
                updateListBuilder({
                    pageLogin: {
                        form: {
                            business,
                            email,
                            mobile,
                            industry,
                        },
                        activeComponent: 'otp',
                    },
                }),
            );
        }
    };

    useEffect(() => {
        if (!isEmail && !isMobile) {
            setAlertMessage('Invalid Email and Mobile number!');
            setAlertDesc('Please input a valid email and mobile number.');
        } else if (!isEmail && isMobile) {
            setAlertMessage('Invalid Email Address!');
            setAlertDesc('Please input a valid email address.');
        } else if (isEmail && !isMobile) {
            setAlertMessage('Invalid Mobile Number!');
        }
    }, [isEmail, isMobile]);

    //SELECT FUNCTIONS
    // const onChangeSelect = (value) => {
    //   console.log(`selected ${value}`);
    // };
    // const onSearch = (val) => {
    //   console.log('search:', val);
    // };
    //
    return (
        <section className="builder_login_cred d-flex-col align-items-center justify-content-center">
            <div className="builder_login_cred_title d-flex-col align-items-center justify-content-center text-center">
                <h3>
                    To continue, please enter your <br /> business name, email, and industry.
                </h3>
            </div>
            <Form name="basic" onFinish={onFinish} layout="vertical">
                <Form.Item
                    label="Business or Project Name"
                    name="business"
                    value={form.business}
                    rules={[
                        {
                            required: true,
                            message: 'Business or Project name is required!',
                        },
                    ]}
                >
                    <Input
                        value={form.business}
                        autoComplete="none"
                        onChange={inputOnChange}
                        placeholder="Enter Business or Project Name"
                    />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Email is required!',
                        },
                    ]}
                >
                    <Input
                        autoComplete="none"
                        onChange={inputOnChange}
                        placeholder="Enter your Email"
                    />
                </Form.Item>
                <Form.Item
                    label="Mobile Number"
                    name="mobile"
                    className="react_phone"
                    rules={[
                        {
                            required: true,
                            message: 'Mobile number is required for OTP verification!',
                        },
                    ]}
                >
                    <ReactPhoneInput
                        required
                        autoComplete="none"
                        id="mobileInput"
                        defaultCountry="PH"
                        placeholder="9876543210"
                        value={phoneValue}
                        onChange={setPhoneValue}
                    />
                </Form.Item>
                <Form.Item
                    label="Industry"
                    name="industry"
                    rules={[
                        {
                            required: true,
                            message:
                                'Required to select what fits you among the list of industries.',
                        },
                    ]}
                >
                    <Select
                        showSearch
                        placeholder="e.g. Commerce"
                        optionFilterProp="children"
                        // onChange={onChangeSelect}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {industries.map((ind, i) => (
                            <Option key={i} value={ind.value}>
                                {ind.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                {!isEmail || !isMobile ? (
                    <Alert message={alertMessage} description={alertDesc} type="error" closable />
                ) : null}
                <Form.Item className="text-center">
                    <Button
                        disabled={disabledForm}
                        loading={loadingForm}
                        className="login-button"
                        type="primary"
                        htmlType="submit"
                    >
                        {loadingForm ? 'Loading' : 'Continue'}
                    </Button>
                </Form.Item>
            </Form>
        </section>
    );
};

export default FormCreds;
