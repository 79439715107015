import React from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

//REDUX
import { homeContentActions } from '../../../redux/reducers/home.reducers';

//SVGs
import contact_hero from '../../../../static/svg/contact-hero-b.svg';
import { IoCloseCircle } from 'react-icons/io5';

export const BuilderContactModal = () => {
    //redux
    const dispatch = useDispatch();

    //redux functions
    const { updateListHome } = homeContentActions;

    //redux non functions
    const { builderConfig } = useSelector((state) => state.homeContents);

    //;;
    const { contactOpen } = builderConfig;

    //functions
    const handleCloseModal = () => {
        dispatch(
            updateListHome({
                builderConfig: {
                    contactOpen: false,
                },
            }),
        );
    };
    const emailTo = () => {
        handleCloseModal();
        window.location.href = 'mailto:hello@ziphersolutions.com';
    };

    return (
        <Modal
            className="modal_contact"
            title="Contact Us"
            visible={contactOpen}
            onOk={handleCloseModal}
            onCancel={handleCloseModal}
        >
            <IoCloseCircle onClick={handleCloseModal} className="close_main_modal" />
            <div className="modal_contact_container d-flex-row align-items-center justify-content-center">
                <div className="modal_contact_container_text d-flex-col gap-1">
                    <h1>Let’s get in touch</h1>
                    <p>
                        To contact us for a quote, business inquiry, or any question you want to
                        ask, you can reach us out directly at this email:
                    </p>
                    <h3 onClick={emailTo}>hello@ziphersolutions.com</h3>
                </div>
                <img src={contact_hero} alt="Contact Us Hero" />
            </div>
        </Modal>
    );
};

export default BuilderContactModal;
