import React from 'react';
import { useSelector } from 'react-redux';

//SUBCOMPONENTS
import FormCreds from './subcomponents/FormCreds';
import FormOTP from './subcomponents/FormOTP';

export const IdeaBuilderLogin = () => {
    //redux non functions
    const { pageLogin } = useSelector((state) => state.builderContents);
    //::
    const { activeComponent } = pageLogin;
    return (
        <section className="builder_login d-flex-col align-items-center justify-content-center">
            {activeComponent === 'form' ? <FormCreds /> : <FormOTP />}
        </section>
    );
};
