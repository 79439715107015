import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//REDUX
import { builderContentActions } from '../../../../redux/reducers/builder.reducers';

//PLUGGINS
import { Popover, Button } from 'antd';
import { ReactSortable } from 'react-sortablejs';

//SVGs
import tuts_img from '../../../../../static/svg/tuts-space.svg';
import icon_trash from '../../../../../static/svg/trash.svg';
import dragable from '../../../../../static/svg/draggable.svg';
import select from '../../../../../static/svg/select.svg';
import { BiCustomize } from 'react-icons/bi';

//SUBCOMPONENTS
import IBCEmpty from './subcomponents/IBCEmpty';
import IBCSymbol from './subcomponents/IBCSymbol';

export const IdeaBuilderContent = () => {
    //redux
    const dispatch = useDispatch();

    //redux functions
    const { deleteComponentsItem, updateWSSortedComponent, updateListBuilder } =
        builderContentActions;

    //redux non functions
    const { pageWorkspace, tutsMode } = useSelector((state) => state.builderContents);

    //::
    const { mainMenu } = pageWorkspace;
    const { isActivated, activeStep } = tutsMode;

    //check if empty
    let allComponents = [];
    mainMenu.forEach((item) => {
        allComponents = [...allComponents, ...item.components];
    });

    const deleteComponent = (value) => {
        // console.log(value, index);
        dispatch(deleteComponentsItem(value));
    };

    //

    const nextStepTuts = () => {
        dispatch(
            updateListBuilder({
                tutsMode: {
                    activeStep: 'sider',
                    isActivated: false,
                },
            }),
        );
    };

    useEffect(() => {
        if (allComponents.length) {
            dispatch(
                updateListBuilder({
                    pageBuilder: {
                        submitDisabled: false,
                    },
                }),
            );
        } else {
            dispatch(
                updateListBuilder({
                    pageBuilder: {
                        submitDisabled: true,
                    },
                }),
            );
        }
    }, [mainMenu]);

    //
    const popContent = (
        <div className="popover_custom_content">
            <img src={tuts_img} alt="" />
            <p>This is the workspace where your components will be added and displayed.</p>
            <div className="popover_custom_content_actions d-flex-row align-items-center justify-content-between">
                <p>4/4</p>
                <Button className="next" type="primary" onClick={nextStepTuts}>
                    Got it!
                </Button>
            </div>
        </div>
    );
    return (
        <section className="idea_builder_content d-flex-row align-items-center justify-content-center gap-3">
            {allComponents.length ? (
                <>
                    {mainMenu.map((item) => {
                        const { header, components } = item;

                        const optionUseState = (defaultValue) => {
                            let value = defaultValue;
                            const getValue = () => value;
                            const setValue = (newValue) => {
                                if (newValue.length) {
                                    dispatch(updateWSSortedComponent(newValue));
                                }
                            };
                            return [getValue, setValue];
                        };
                        const [customOption, setCustomOption] = optionUseState(components);

                        return (
                            <>
                                {customOption().length ? (
                                    <div className="frame_container d-flex-col align-items-center gap-2">
                                        <p className="frame_container_title">{header}</p>
                                        <ReactSortable
                                            className="frame_container_conponents d-flex-col gap-2"
                                            list={customOption().map((item) => ({
                                                ...item,
                                                chosen: true,
                                            }))}
                                            setList={(newValue) => setCustomOption(newValue)}
                                        >
                                            {customOption().map((component, i) => (
                                                <div
                                                    key={i}
                                                    className="draggable_container d-flex-row align-items-center"
                                                >
                                                    <div className="draggable_container_item d-flex-row align-items-center justify-content-between">
                                                        <div className="draggable_container_item_left d-flex-row align-items-center">
                                                            {component.icon ? (
                                                                <img
                                                                    src={component.icon}
                                                                    alt="Zipher Icon"
                                                                />
                                                            ) : (
                                                                <BiCustomize />
                                                            )}
                                                            <p> {component.name}</p>
                                                        </div>
                                                        <div className="draggable_container_item_right d-flex-row align-items-center">
                                                            <img
                                                                onClick={() =>
                                                                    deleteComponent(component)
                                                                }
                                                                src={icon_trash}
                                                                alt="Delete Icon"
                                                            />
                                                            <img src={dragable} alt="Drag Icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </ReactSortable>
                                        <IBCSymbol />
                                    </div>
                                ) : null}
                            </>
                        );
                    })}
                </>
            ) : (
                <IBCEmpty />
            )}
            {activeStep === 'content' && isActivated ? (
                <div className="idea_builder_content_tuts">
                    <Popover placement="left" content={popContent} title="Title">
                        <div className="hoverme d-flex-row">
                            <img src={select} alt="" />
                        </div>
                    </Popover>
                </div>
            ) : null}
            {(activeStep === 'sider' ||
                activeStep === 'sidertitle' ||
                activeStep === 'sidermenu') &&
            isActivated ? (
                <div className="idea_builder_content_shadow"></div>
            ) : null}
        </section>
    );
};

export default IdeaBuilderContent;
